import merge from "lodash/merge";
import PropTypes from "prop-types";
import React from "react";
import { Line } from "react-chartjs-2";
import { useCompanyTheme, useIsTablet } from "../hooks/common.hooks";
import { tooltipsBaseOption } from "../utils/chart-util";
import { formatNumber, formatNumberWithText } from "../utils/numberFormater";

const buildChartData = (title, lineChartData) => {
  return {
    datasets: [
      // These two will be in the same stack.
      {
        type: "line",
        label: title,
        fill: false,
        backgroundColor: "#162029",
        borderColor: "#162029",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#162029",
        pointBackgroundColor: "#162029",
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: "#162029",
        pointHoverBorderColor: "#162029",
        pointHoverBorderWidth: 3,
        pointRadius: 1,
        pointHitRadius: 3,
        data: lineChartData,
      },
    ],
  };
};

const buildChartOption = (
  isTablet,
  lineChartData,
  selectedIndex,
  customChartOptions
) => {
  let unitFilter = "";
  switch (selectedIndex) {
    case 0:
    case 1:
      unitFilter = "month";
      break;
    default:
      unitFilter = "year";
      break;
  }
  const baseOptions = {
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          stacked: true,
          ticks: {
            min:
              isTablet &&
              !isTablet &&
              lineChartData.labels &&
              lineChartData.labels.length > 0
                ? lineChartData.labels[7]
                : "none",
            maxTicksLimit: 15,
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            beginAtZero: false,
            source: "auto",
          },
          bounds: "data",
          type: "time",
          time: {
            unit: unitFilter,
          },
          distribution: "linear",
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          display: !isTablet,
          position: isTablet ? "right" : "left",
          ticks: {
            beginAtZero: false,
            display: true,
            userCallback(value) {
              return formatNumberWithText(value, 1);
            },
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      ...tooltipsBaseOption,
      callbacks: {
        label(tooltipItem, data) {
          const { yLabel = 0 } = tooltipItem;
          const chartValue = yLabel;
          const chartValueWithCommas = formatNumber(Math.abs(chartValue));
          const chartTitle = data.datasets[0].label;
          const result = `${chartTitle} ${
            chartValue > 0
              ? `$${chartValueWithCommas}`
              : `-$${chartValueWithCommas}`
          } `;
          return result.trim();
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
  };

  return merge(baseOptions, customChartOptions);
};

const buildChartLegend = (legendDisplay) => {
  return {
    display: legendDisplay,
    position: "bottom",
    fullWidth: true,
    reverse: false,
    labels: {
      fontColor: "#000",
    },
  };
};

const LineChartComponent = ({
  lineChartData = [],
  legendDisplay = true,
  title = "",
  customChartOptions = {},
}) => {
  const isTablet = useIsTablet();
  const companyTheme = useCompanyTheme();
  const chartData = buildChartData(
    title,
    lineChartData,
    companyTheme?.primaryColor || "#000000"
  );
  const chartOption = buildChartOption(
    isTablet,
    lineChartData,
    customChartOptions
  );
  const chartLegend = buildChartLegend(legendDisplay);

  return (
    <div className="line-chart-container">
      <div className="line-chart-size">
        <Line
          data={chartData}
          options={chartOption}
          legend={chartLegend}
          height={null}
          width={null}
        />
      </div>
    </div>
  );
};

export default LineChartComponent;
export { LineChartComponent };

LineChartComponent.propTypes = {
  title: PropTypes.string,
  legendDisplay: PropTypes.bool,
};
